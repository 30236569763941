import { DOCUMENT } from "@angular/common";
import { Inject, Renderer2 } from "@angular/core";
import { Injectable } from "@angular/core";

/**
 * Сервис загрузки внешних js-файлов и подключения их к странице
 */
@Injectable({
  providedIn: "root",
})
export class AssetsService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Загружает внешний js-скрипт и вставляет его в заголовок страницы
   * @param renderer рендер страницы
   * @param src ссылка для загрузки js-файла
   * @returns элемент скрипта
   */
  loadScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    renderer.appendChild(this.document.head, script);
    return script;
  }

  /**
   * Загружает внешний css-файл и вставляет его в заголовок страницы
   * @param renderer рендер страницы
   * @param href ссылка для загрузки css-файла
   * @returns элемент скрипта
   */
  loadStylesheet(renderer: Renderer2, href: string) {
    const style = renderer.createElement("link");
    style.rel = "stylesheet";
    style.type = "text/css";
    style.href = href;
    renderer.appendChild(this.document.head, style);
  }
}
