/* src/app/components/tel-confirmation/tel-confirmation.component.scss */
#confirm-widget {
  visibility: hidden;
}
#confirm-widget-loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=tel-confirmation.component.css.map */
