import * as i0 from '@angular/core';
import { inject, ElementRef, NgZone, Directive, Input, Pipe } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { MASKITO_DEFAULT_ELEMENT_PREDICATE, maskitoTransform, Maskito, MASKITO_DEFAULT_OPTIONS } from '@maskito/core';
class MaskitoDirective {
  constructor() {
    this.elementRef = inject(ElementRef).nativeElement;
    this.ngZone = inject(NgZone);
    this.maskedElement = null;
    this.options = null;
    this.elementPredicate = MASKITO_DEFAULT_ELEMENT_PREDICATE;
    const accessor = inject(DefaultValueAccessor, {
      self: true,
      optional: true
    });
    if (accessor) {
      const original = accessor.writeValue.bind(accessor);
      accessor.writeValue = value => {
        original(this.options ? maskitoTransform(String(value ?? ''), this.options) : value);
      };
    }
  }
  async ngOnChanges() {
    const {
      elementPredicate,
      options,
      maskedElement,
      elementRef,
      ngZone
    } = this;
    maskedElement?.destroy();
    if (!options) {
      return;
    }
    const predicateResult = await elementPredicate(elementRef);
    if (this.elementPredicate !== elementPredicate || this.options !== options) {
      // Ignore the result of the predicate if the
      // maskito element (or its options) has changed before the predicate was resolved.
      return;
    }
    ngZone.runOutsideAngular(() => {
      this.maskedElement = new Maskito(predicateResult, options);
    });
  }
  ngOnDestroy() {
    this.maskedElement?.destroy();
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: MaskitoDirective,
      deps: [],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "16.2.12",
      type: MaskitoDirective,
      isStandalone: true,
      selector: "[maskito]",
      inputs: {
        options: ["maskito", "options"],
        elementPredicate: ["maskitoElement", "elementPredicate"]
      },
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: MaskitoDirective,
  decorators: [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[maskito]'
    }]
  }],
  ctorParameters: function () {
    return [];
  },
  propDecorators: {
    options: [{
      type: Input,
      args: ['maskito']
    }],
    elementPredicate: [{
      type: Input,
      args: ['maskitoElement']
    }]
  }
});
class MaskitoPipe {
  transform(value, maskitoOptions) {
    return maskitoTransform(String(value ?? ''), maskitoOptions ?? MASKITO_DEFAULT_OPTIONS);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: MaskitoPipe,
      deps: [],
      target: i0.ɵɵFactoryTarget.Pipe
    });
  }
  static {
    this.ɵpipe = i0.ɵɵngDeclarePipe({
      minVersion: "14.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: MaskitoPipe,
      isStandalone: true,
      name: "maskito"
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: MaskitoPipe,
  decorators: [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'maskito'
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { MaskitoDirective, MaskitoPipe };
