import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { AlertController } from "@ionic/angular/standalone";
import { ApiService } from "src/app/services/api.service";
import { AssetsService } from "src/app/services/assets.service";
import { environment } from "src/environments/environment";

declare let window: any;

@Component({
  selector: "app-tel-confirmation",
  standalone: true,
  styleUrls: ["./tel-confirmation.component.scss"],
  templateUrl: "./tel-confirmation.component.html",
})
export class TelConfirmationComponent implements OnInit {
  @ViewChild("confirm_widget") confirmWidget: ElementRef;
  /**
   * Событие, когда виджет подтверждения не удалось загрузить
   */
  @Output() failedToLoad: EventEmitter<boolean>;
  /**
   * Параметры для виджета подтверждения телефона
   */
  @Input() params: {
    /**
     * Есть ли платная подписка, чтобы показать информацию о промокоде
     */
    subActive: boolean;
    /**
     * Телефон для подтверждения
     */
    tel: string;
  };
  /**
   * Событие, когда телефон успешно подтверждён
   */
  @Output() telConfirmed: EventEmitter<boolean>;

  constructor(
    private alertCtrl: AlertController,
    private api: ApiService,
    private renderer: Renderer2,
    private assetsService: AssetsService,
  ) {
    this.telConfirmed = new EventEmitter<boolean>();
    this.failedToLoad = new EventEmitter<boolean>();
  }

  ngOnInit() {
    let confirmationKey: string;
    let phone = this.params.tel.replace(/[^\d]/g, "");

    const scriptElement = this.assetsService.loadScript(
      this.renderer,
      "https://cdn.direct.i-dgtl.ru/VerifyWidget.umd.min.js",
    );
    scriptElement.onerror = () => {
      this.failedToLoad.emit();
    };
    scriptElement.onload = () => {
      window.VerifyWidget.mount(
        "#confirm-widget",
        {
          captchaSiteKey: environment.telVerifyCaptchaKey,
          destination: phone,
          widgetId: environment.telVerifyWidgetId,
        },
        (key: string) => {
          confirmationKey = key;
          this.api.post("v1/user/tel/send", { key: confirmationKey }, true).subscribe({
            next: (res: any) => {},
          });
        },
        () => {
          this.api.post("v1/user/tel/verify", { key: confirmationKey }, true).subscribe({
            next: async (res: any) => {
              if (res.success) {
                if (this.params.subActive) {
                  this.api.toastPresent("Телефон успешно подтверждён!");
                  this.telConfirmed.emit();
                } else {
                  const alert = await this.alertCtrl.create({
                    buttons: [
                      {
                        handler: () => {
                          this.telConfirmed.emit();
                        },
                        role: "confirm",
                        text: "Понял(а)",
                      },
                    ],
                    message: `Спасибо! Теперь вам доступен промокод MOBILE 
                    со скидкой на первую активацию тарифа "ПРОФИ"! 
                    Воспользуйтесь им на экране управления тарифным планом.`,
                  });

                  await alert.present();
                }
              }
            },
          });
        },
      );
      // таймаут, чтобы виджет смонтировался и подгрузились стили
      setTimeout(() => {
        this.api.loadingDismiss();
        this.confirmWidget.nativeElement.style.visibility = "visible";
      }, 1000);
    };
    this.assetsService.loadStylesheet(this.renderer, "https://cdn.direct.i-dgtl.ru/VerifyWidget.css");
  }
}
