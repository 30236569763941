import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
/**
 * Сервис с различными полезными утилитами
 */
export class ToolsService {
  /**
   * Регулярка проверки валидного мобильного телефона СНГ
   */
  phoneRegexp: RegExp;

  constructor() {
    this.phoneRegexp = new RegExp(
      /^((\+373|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993)[\- ]?)?\(?\d{3,5}\)?[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}(([\- ]?\d{1})?[\- ]?\d{1})?$/,
      "i",
    );
  }

  /**
   * Копирует текст в буфер обмена. Работает во всех основных браузерах
   * @param text текст для копирования
   */
  copyToClipboard(text: string) {
    let textArea;

    let isOS = () => {
      return navigator.userAgent.match(/ipad|iphone/i);
    };

    let createTextArea = (text) => {
      textArea = document.createElement("textArea");
      textArea.value = text;
      document.body.appendChild(textArea);
    };

    let selectText = () => {
      let range: Range, selection: Selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    };

    let copyToClipboard = () => {
      document.execCommand("copy");
      document.body.removeChild(textArea);
    };

    createTextArea(text);
    selectText();
    copyToClipboard();
  }

  /**
   * Проверка почтового адреса на валидность
   * @param { string } email почтовый адрес для проверки
   * @returns { boolean } валидный адрес или нет
   */
  emailValid(email: string): boolean {
    return /^.+@.+\..+$/i.test(email);
  }

  /**
   * Проверка номера телефона СНГ на валидность
   * @param { string } tel номер телефона для проверки
   * @returns { boolean } валидный номер или нет
   */
  phoneValid(tel: string): boolean {
    return this.phoneRegexp.test(tel);
  }
}
